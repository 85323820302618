<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="topsubmenu auto">
        <a-radio-group default-value="2" size="small" @change="changeSubmenu" button-style="solid">
          <a-radio-button value="1">
            账号安全策略
          </a-radio-button>
          <a-radio-button value="4">
            Syslog配置
          </a-radio-button>
          <a-radio-button value="5">
            属性配置
          </a-radio-button>
          <a-radio-button value="2">
            基本配置
          </a-radio-button>
          <a-radio-button value="3">
            系统准入策略
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>基本配置</b></span>
        </div>
      </div>
      <div class="department-content auto" id="no-margin-form">
        <a-spin :spinning="loading">
          <div class="box auto">
            <div class="box-title auto">
              <!-- <div class="fl">密码校验管理</div> -->
            </div>
            <div class="box-con auto m-t-20 no-bor">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :label-col="{span: 3}"
                :wrapper-col="{span: 15}"
                style="max-width: 800px;"
              >
                <a-form-model-item label="主题色logo" prop="min">
                  <div class="logo-box auto">
                    <img :src="form.logo_theme">
                  </div>
                  <!-- <a-button type="primary" size="small" class="m-t-5">更换图片</a-button> -->
                  <a-upload
                    name="file"
                    :multiple="true"
                    :headers="headers"
                    :transform-file="logoTheme"
                    accept="image/*"
                    :showUploadList="false"
                  >
                    <a-button type="primary" size="small" class="m-t-5">更换图片</a-button>
                  </a-upload>
                  <p class="font12">建议尺寸为60*60px，宽度按比例缩放，格式为.png</p>
                </a-form-model-item>
                <a-form-model-item label="白色logo" prop="min">
                  <div class="logo-box logo-box-grey auto">
                    <img :src="form.logo_white">
                  </div>
                  <!-- <a-button type="primary" size="small" class="m-t-5">更换图片</a-button> -->
                  <a-upload
                    name="file"
                    :multiple="true"
                    :headers="headers"
                    :transform-file="logoWhite"
                    accept="image/*"
                    :showUploadList="false"
                  >
                    <a-button type="primary" size="small" class="m-t-5">更换图片</a-button>
                  </a-upload>
                  <p class="font12">建议尺寸为60*60px，宽度按比例缩放，格式为.png</p>
                </a-form-model-item>
                <a-form-model-item label="主题色全logo" prop="min">
                  <div class="logo-box logo-box2 auto">
                    <img :src="form.logo_full_theme">
                  </div>
                  <!-- <a-button type="primary" size="small" class="m-t-5">更换图片</a-button> -->
                  <a-upload
                    name="file"
                    :multiple="true"
                    :headers="headers"
                    :transform-file="logoFullTheme"
                    accept="image/*"
                    :showUploadList="false"
                  >
                    <a-button type="primary" size="small" class="m-t-5">更换图片</a-button>
                  </a-upload>
                  <p class="font12">建议logo高度为100px，宽度按比例缩放，格式为.png</p>
                </a-form-model-item>
                <a-form-model-item label="白色全logo" prop="min">
                  <div class="logo-box logo-box2 logo-box-grey auto">
                    <img :src="form.logo_full_white">
                  </div>
                  <!-- <a-button type="primary" size="small" class="m-t-5">更换图片</a-button> -->
                  <a-upload
                    name="file"
                    :multiple="true"
                    :headers="headers"
                    :transform-file="logoFullWhite"
                    accept="image/*"
                    :showUploadList="false"
                  >
                    <a-button type="primary" size="small" class="m-t-5">更换图片</a-button>
                  </a-upload>
                  <p class="font12">建议logo高度为100px，宽度按比例缩放，格式为.png</p>
                </a-form-model-item>
                <a-form-model-item label="Favicon" prop="min">
                  <div class="logo-box logo-box3 auto">
                    <img :src="form.favicon">
                  </div>
                  <!-- <a-button type="primary" size="small" class="m-t-5">更换图片</a-button> -->
                  <a-upload
                    name="file"
                    :multiple="true"
                    :headers="headers"
                    :transform-file="logoFavicon"
                    accept="image/*"
                    :showUploadList="false"
                  >
                    <a-button type="primary" size="small" class="m-t-5">更换图片</a-button>
                  </a-upload>
                  <p class="font12">建议尺寸为16*16px，宽度按比例缩放，格式为.ico或.svg</p>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="saveBasicsetting">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </a-spin>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      headers: {
        authorization: 'authorization-text',
      },
      form: {
        logo_theme: '',
        logo_white: '',
        logo_full_theme: '',
        logo_full_white: '',
        favicon: ''
      }
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/configuration/index');
          break;
        case 2:
          this.$router.push('/system/configuration/basic');
          break;
        case 3:
          this.$router.push('/system/configuration/accesspolicy');
          break;
        case 4:
          this.$router.push('/system/configuration/syslogsetting');
          break;
        case 5:
          this.$router.push('/system/configuration/property');
          break;
        default:
          break;
      }
    },
    async saveBasicsetting () {
      let form = {
        logo_theme: this.form['logo_theme'].split(',')[1],
        logo_white: this.form['logo_white'].split(',')[1],
        logo_full_theme: this.form['logo_full_theme'].split(',')[1],
        logo_full_white: this.form['logo_full_white'].split(',')[1],
        favicon: this.form['favicon'].split(',')[1]
      }
      this.loading = true
      let res = await System.putBasicsetting(form)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    logoTheme (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.logo_theme = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    logoWhite (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.logo_white = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    logoFullTheme (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.logo_full_theme = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    logoFullWhite (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.logo_full_white = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    logoFavicon (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.favicon = reader.result
      }
      reader.onerror = (error) => {
        this.$message.error(error)
      }
    },
    async getBasicsetting () {
      this.loading = true
      let res = await System.getBasicsetting()
      this.loading = false
      if (!res['code']) {
        this.form = {
          logo_theme: 'data:image/jpeg;base64,' + res['data']['logo_theme'],
          logo_white: 'data:image/jpeg;base64,' + res['data']['logo_white'],
          logo_full_theme: 'data:image/jpeg;base64,' + res['data']['logo_full_theme'],
          logo_full_white: 'data:image/jpeg;base64,' + res['data']['logo_full_white'],
          favicon: 'data:image/jpeg;base64,' + res['data']['favicon']
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getBasicsetting()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 0px;
  color: rgba(0,0,0,0.85);
}

.logo-box {
  width: 60px;
  height: 60px;
  border: 1px dashed rgba(0,0,0,0.15);
  border-radius: 2px;
}
.logo-box img {
  width: 100%;
  height: 100%;
}
.logo-box-grey {
  background: #f5f5f5;
}
.logo-box2 {
  width: 240px;
}
.logo-box3 {
  width: 24px;
  height: 24px;
}
.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  width: 100%;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  width: 100%;
  padding-bottom: 20px;
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}

.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}

</style>
